<template>
  <land-section
    id="about-contact"
    :color="backColor"
    :space="space"
  >
    <v-container
      style="max-width: 1000px;"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <div class="co-flex-col co-justify-center co-items-center">
            <span class="text-h5 black--text ">{{ title }}</span>
            <div class="co-w32 co-h4 primary mt-4" />
            <div class="co-w32 co-h24" />
          </div>
        </v-col>
        <v-col
          cols="12"
        >
          <land-online-message
            ref="wdOnlineMsg"
            @submit="onSubmit"
          />
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'SectionAboutMessage',
    props: {
      backColor: {
        type: String,
        default: 'transparent'
      },
      space: {
        type: [Number, String],
        default: 40
      }
    },
    data () {
      return {
        title: '在线留言'
      }
    },
    methods: {
      reset () {
        const wd = this.$refs.wdOnlineMsg
        if (wd) {
          wd.reset()
        }
      },
      onSubmit (ev) {
        this.$emit('submit', ev)
      }
    }
  }
</script>
